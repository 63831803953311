import React, { useState, useRef, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  TextField, 
  Button, 
  RadioGroup, 
  Radio,
  FormControlLabel,
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Avatar,
  Fade,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Check as CheckIcon } from '@mui/icons-material';
import { Undo as UndoIcon } from '@mui/icons-material';
import { Download as DownloadIcon } from '@mui/icons-material';
import styled from '@mui/styled-engine';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { keyframes } from '@mui/system';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2563eb',
      light: '#3b82f6',
      dark: '#1d4ed8',
    },
    background: {
      default: '#f8fafc',
      paper: '#ffffff',
    }
  },
  typography: {
    h4: {
      fontWeight: 700,
      letterSpacing: '-0.5px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: '8px 16px',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
        }
      }
    }
  }
});

const Thumbnail = styled('div')(({ theme, selected }) => ({
  width: 80,
  height: 80,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  cursor: 'pointer',
  position: 'relative',
  border: `2px solid ${selected ? theme.palette.primary.main : 'transparent'}`,
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[3],
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
}));

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const ShimmeringText = styled('span')({
  background: 'linear-gradient(90deg, #2563eb 0%, #60a5fa 50%, #2563eb 100%)',
  backgroundSize: '200% auto',
  animation: `${shimmer} 2s linear infinite`,
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  color: 'transparent',
  display: 'inline-block',
});

const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const TypewriterText = ({ phrases, typingSpeed = 150, deleteSpeed = 75, pauseDuration = 2000 }) => {
  const [text, setText] = useState('');
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;

    const type = () => {
      const currentPhrase = phrases[phraseIndex];
      
      if (!isDeleting) {
        setText(currentPhrase.substring(0, text.length + 1));
        
        if (text.length === currentPhrase.length) {
          timer = setTimeout(() => {
            setIsDeleting(true);
          }, pauseDuration);
          return;
        }
      } else {
        setText(currentPhrase.substring(0, text.length - 1));
        
        if (text.length === 0) {
          setIsDeleting(false);
          setPhraseIndex((prev) => (prev + 1) % phrases.length);
          return;
        }
      }

      timer = setTimeout(
        type,
        isDeleting ? deleteSpeed : typingSpeed
      );
    };

    timer = setTimeout(type, isDeleting ? deleteSpeed : typingSpeed);
    return () => clearTimeout(timer);
  }, [text, phraseIndex, isDeleting, phrases, typingSpeed, deleteSpeed, pauseDuration]);

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography
        variant="h6"
        component="span"
        color="text.secondary"
        sx={{
          fontWeight: 400,
          position: 'relative',
          whiteSpace: 'nowrap',
          '&::after': {
            content: '"|"',
            position: 'relative',
            marginLeft: '2px',
            color: 'primary.main',
            animation: `${blink} 1s step-end infinite`,
          }
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const downloadImage = (imageUrl, filename = 'generated-image.png') => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function App() {
  const [messages, setMessages] = useState([{
    type: 'bot',
    content: [],
    isImage: true,
    isMultiple: false
  }]);
  const [image, setImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [mode, setMode] = useState('replace');
  const [inputText1, setInputText1] = useState('');
  const [inputText2, setInputText2] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kernelSize, setKernelSize] = useState(5);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      setMessages([
        { 
          type: 'bot', 
          content: [reader.result],
          isImage: true,
          isMultiple: false
        }
      ]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userMessage = mode === 'replace' 
        ? `Replace "${inputText1}" with "${inputText2}"`
        : `Remove "${inputText1}"`;

      // Show user's message immediately
      setMessages(prev => [...prev, { type: 'user', content: userMessage, isImage: false }]);

      // Get the last bot message with images
      const lastBotMessage = [...messages].reverse().find(m => m.type === 'bot' && m.isImage);
      // Use either the selected image from the last generation, or the original uploaded image
      const imageToUse = lastBotMessage?.isMultiple 
        ? lastBotMessage.content[selectedImageIndex]
        : image;

      // Prepare form data for the API call
      const formData = new FormData();
      const imageBlob = await fetch(imageToUse).then(r => r.blob());
      formData.append('file', imageBlob, 'image.png');
      formData.append('detection_prompt', inputText1);
      formData.append('inpaint_prompt', inputText2);
      formData.append('inpaint_negative_prompt', '');
      formData.append('num_images', '4'); // Request 4 variations
      formData.append('seed', Math.floor(Math.random() * 1000000).toString());
      formData.append('kernel_size', '5');

      const response = await fetch('https://1wcd4t45a2vh19-8000.proxy.runpod.net/process-image/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('API request failed');

      const data = await response.json();
      
      // Debug logs
      console.log('API Response:', data);
      console.log('Number of images received:', data.images?.length);
      console.log('First image sample:', data.images?.[0]?.substring(0, 100)); // Show first 100 chars
      
      // Check if we're getting base64 strings with proper prefix
      const processedImages = data.images.map(img => {
        // If the image doesn't start with data:image, add the prefix
        if (!img.startsWith('data:image')) {
          return `data:image/png;base64,${img}`;
        }
        return img;
      });

      console.log('Processed first image sample:', processedImages[0]?.substring(0, 100));

      setMessages(prev => [...prev, {
        type: 'bot',
        content: processedImages,
        isImage: true,
        isMultiple: true
      }]);
      
      // Scroll after setting messages
      setTimeout(scrollToBottom, 100); // Small delay to ensure new content is rendered
      
    } catch (error) {
      console.error('Error:', error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }

    setInputText1('');
    setInputText2('');
  };

  const handleUndo = () => {
    setMessages(prev => {
      const newMessages = [...prev]; 
      // Find and remove the last bot message and its corresponding user message
      const lastBotIndex = newMessages.findLastIndex(m => m.type === 'bot' && m.isMultiple);
      if (lastBotIndex !== -1) {
        // Remove bot message and the user message that came before it
        newMessages.splice(lastBotIndex - 1, 2);
      }
      return newMessages;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                background: 'linear-gradient(45deg, #2563eb 30%, #60a5fa 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                mb: 2,
                fontWeight: 700
              }}
            >
              Type to Edit
            </Typography>
            
            <Box sx={{ height: '3rem', display: 'flex', justifyContent: 'center', mb: 3 }}>
              <TypewriterText
                phrases={[
                  "Replace the sky with a sunset...",
                  "Remove unwanted objects...",
                  "Change the color of your car...",
                  "Transform day into night...",
                  "Edit images with simple text..."
                ]}
              />
            </Box>

            <Box 
              sx={{ 
                display: 'flex', 
                gap: 2, 
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: 4
              }}
            >
              {[
                { icon: '🎨', text: 'Replace elements', desc: 'Swap any object or area' },
                { icon: '✨', text: 'Remove objects', desc: 'Clean up your images' },
                { icon: '🔄', text: 'Multiple variations', desc: 'Get different options' }
              ].map((feature, index) => (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    bgcolor: 'rgba(37, 99, 235, 0.1)',
                    p: 2,
                    borderRadius: 2,
                    transition: 'all 0.2s ease-in-out',
                    cursor: 'default',
                    minWidth: '180px',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      bgcolor: 'rgba(37, 99, 235, 0.15)',
                      boxShadow: 2
                    }
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 1 }}>{feature.icon}</Typography>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: 'primary.main',
                      fontWeight: 500,
                      mb: 0.5
                    }}
                  >
                    {feature.text}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '0.875rem'
                    }}
                  >
                    {feature.desc}
                  </Typography>
                </Paper>
              ))}
            </Box>
          </Box>
          
          <Paper elevation={0} sx={{ p: 4 }}>
            {!image ? (
              <Box 
                sx={{ 
                  textAlign: 'center',
                  py: 8,
                  px: 4,
                  border: '2px dashed',
                  borderColor: 'grey.300',
                  borderRadius: 3,
                  bgcolor: 'grey.50'
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Drop your image here
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                  or
                </Typography>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="image-upload">
                  <Button 
                    variant="contained" 
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Choose File
                  </Button>
                </label>
              </Box>
            ) : (
              <>
                <Box 
                  sx={{ 
                    flexGrow: 1,
                    overflowY: 'auto',
                    p: 3,
                    backgroundColor: 'grey.50',
                    borderRadius: 2,
                    mb: 3,
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                  }}
                >
                  {messages.map((message, index) => (
                    <Fade in={true} key={index} timeout={300}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
                          gap: 2,
                          alignItems: 'flex-start',
                          maxWidth: '100%'
                        }}
                      >
                        {message.type === 'bot' && (
                          <Avatar sx={{ bgcolor: 'primary.main', boxShadow: 1 }}>AI</Avatar>
                        )}

                        <Paper
                          elevation={0}
                          sx={{
                            p: 2,
                            maxWidth: message.isImage ? '600px' : '70%',
                            width: message.isImage ? '100%' : 'auto',
                            bgcolor: message.type === 'user' ? 'primary.main' : 'background.paper',
                            color: message.type === 'user' ? 'white' : 'text.primary',
                            borderRadius: 2,
                            boxShadow: 1
                          }}
                        >
                          {message.isImage && message.isMultiple ? (
                            <Box sx={{ width: '100%' }}>
                              {/* Main selected image */}
                              <Box 
                                sx={{ 
                                  mb: 2, 
                                  borderRadius: 2,
                                  overflow: 'hidden',
                                  boxShadow: 3,
                                  backgroundColor: 'background.paper',
                                  position: 'relative',
                                  height: 400,
                                }}
                              >
                                <img
                                  src={message.content[selectedImageIndex]}
                                  alt="Selected"
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                                <IconButton
                                  onClick={() => downloadImage(message.content[selectedImageIndex])}
                                  sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    '&:hover': {
                                      backgroundColor: 'rgba(255, 255, 255, 1)',
                                    },
                                  }}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Box>

                              {/* Thumbnail strip */}
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  overflowX: 'auto',
                                  pb: 1,
                                  '&::-webkit-scrollbar': {
                                    height: 6,
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'rgba(0,0,0,0.1)',
                                    borderRadius: 3,
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,0.2)',
                                    borderRadius: 3,
                                    '&:hover': {
                                      backgroundColor: 'rgba(0,0,0,0.3)',
                                    },
                                  },
                                }}
                              >
                                {message.content.map((img, imgIndex) => (
                                  <Tooltip 
                                    key={imgIndex} 
                                    title={`Variation ${imgIndex + 1}`}
                                    placement="top"
                                  >
                                    <Thumbnail
                                      key={imgIndex}
                                      selected={selectedImageIndex === imgIndex}
                                      onClick={() => setSelectedImageIndex(imgIndex)}
                                    >
                                      <img src={img} alt={`Variation ${imgIndex + 1}`} />
                                      {selectedImageIndex === imgIndex && (
                                        <Box
                                          sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'rgba(37, 99, 235, 0.1)',
                                          }}
                                        >
                                          <CheckIcon sx={{ color: 'primary.main', fontSize: 20 }} />
                                        </Box>
                                      )}
                                      <IconButton
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent thumbnail selection when clicking download
                                          downloadImage(img, `generated-image-${imgIndex + 1}.png`);
                                        }}
                                        sx={{
                                          position: 'absolute',
                                          right: 4,
                                          top: 4,
                                          padding: '4px',
                                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                          '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                          },
                                        }}
                                        size="small"
                                      >
                                        <DownloadIcon sx={{ fontSize: 16 }} />
                                      </IconButton>
                                    </Thumbnail>
                                  </Tooltip>
                                ))}
                                
                                <Tooltip title="Generate new variations">
                                  <Thumbnail
                                    selected={false}
                                    onClick={async () => {
                                      setIsLoading(true);
                                      try {
                                        // Toggle kernel size between 5 and 25
                                        const newKernelSize = kernelSize === 5 ? 25 : 5;
                                        console.log('Setting new kernel size:', newKernelSize);
                                        setKernelSize(newKernelSize);

                                        // Find the second-to-last bot message (N-1)
                                        const botMessages = messages.filter(m => m.type === 'bot' && m.isMultiple);
                                        const previousGeneration = botMessages[botMessages.length - 2];
                                        
                                        // If there's no previous generation, use the original image
                                        const imageToUse = previousGeneration 
                                          ? previousGeneration.content[selectedImageIndex]
                                          : image;

                                        const lastUserMessage = [...messages]
                                          .reverse()
                                          .find(m => m.type === 'user');
                                        if (!lastUserMessage) return;

                                        // Parse the last user message to get prompts
                                        const matches = lastUserMessage.content.match(/"([^"]*)"/g);
                                        if (!matches) return;
                                        
                                        // Extract the prompts and temporarily store them to show in loading state
                                        const detectionPrompt = matches[0].replace(/"/g, '');
                                        const inpaintPrompt = matches[1]?.replace(/"/g, '') || '';
                                        
                                        // Temporarily update input values for loading message
                                        setInputText1(detectionPrompt);
                                        setInputText2(inpaintPrompt);

                                        // Prepare form data
                                        const formData = new FormData();
                                        const imageBlob = await fetch(imageToUse).then(r => r.blob());
                                        formData.append('file', imageBlob, 'image.png');
                                        formData.append('detection_prompt', detectionPrompt);
                                        formData.append('inpaint_prompt', inpaintPrompt);
                                        formData.append('inpaint_negative_prompt', '');
                                        formData.append('num_images', '4');
                                        formData.append('seed', Math.floor(Math.random() * 1000000).toString());
                                        formData.append('kernel_size', newKernelSize.toString());

                                        // Debug log to verify formData
                                        console.log('Sending kernel_size:', formData.get('kernel_size'));

                                        const response = await fetch('https://1wcd4t45a2vh19-8000.proxy.runpod.net/process-image/', {
                                          method: 'POST',
                                          body: formData,
                                        });

                                        if (!response.ok) throw new Error('API request failed');

                                        const data = await response.json();
                                        const processedImages = data.images.map(img => 
                                          !img.startsWith('data:image') ? `data:image/png;base64,${img}` : img
                                        );

                                        // Remove the last bot message before adding the new one
                                        setMessages(prev => {
                                          const newMessages = [...prev];
                                          const lastBotIndex = newMessages.findLastIndex(m => m.type === 'bot' && m.isMultiple);
                                          if (lastBotIndex !== -1) {
                                            newMessages.splice(lastBotIndex, 1);
                                          }
                                          return [
                                            ...newMessages,
                                            {
                                              type: 'bot',
                                              content: processedImages,
                                              isImage: true,
                                              isMultiple: true
                                            }
                                          ];
                                        });
                                        
                                        // Reset selected image index
                                        setSelectedImageIndex(0);
                                        
                                        // Ensure scroll happens after state update
                                        setTimeout(scrollToBottom, 100);
                                      } catch (error) {
                                        console.error('Error:', error);
                                      } finally {
                                        setIsLoading(false);
                                        // Reset input values
                                        setInputText1('');
                                        setInputText2('');
                                      }
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        gap: 0.5,
                                      }}
                                    >
                                      <AutorenewIcon color="primary" />
                                      <Typography variant="caption" color="primary">
                                        Retry
                                      </Typography>
                                    </Box>
                                  </Thumbnail>
                                </Tooltip>
                              </Box>
                            </Box>
                          ) : message.isImage && !message.isMultiple ? (
                            <Box 
                              sx={{ 
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                bgcolor: 'background.paper',
                                borderRadius: 2,
                                overflow: 'hidden',
                                boxShadow: 3,
                                '& img': {
                                  maxWidth: '100%',
                                  width: 'auto',
                                  height: 'auto',
                                  maxHeight: '70vh', // Limit height to 70% of viewport height
                                  display: 'block',
                                  margin: '0 auto',
                                }
                              }}
                            >
                              <img
                                src={message.content}
                                alt="Uploaded"
                                style={{
                                  objectFit: 'contain',
                                }}
                              />
                            </Box>
                          ) : (
                            <Typography>{message.content}</Typography>
                          )}
                        </Paper>

                        {message.type === 'user' && (
                          <Avatar sx={{ bgcolor: 'grey.400', boxShadow: 1 }}>You</Avatar>
                        )}
                      </Box>
                    </Fade>
                  ))}

                  <div ref={messagesEndRef} />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 1,
                      position: 'sticky',
                      bottom: 16,
                      zIndex: 1000,
                    }}
                  >
                    {messages.length > 1 && (
                      <Tooltip title="Undo last generation">
                        <Fade in={true}>
                          <IconButton
                            onClick={handleUndo}
                            sx={{
                              bgcolor: 'background.paper',
                              boxShadow: 2,
                              '&:hover': {
                                bgcolor: 'grey.100',
                              },
                            }}
                          >
                            <UndoIcon />
                          </IconButton>
                        </Fade>
                      </Tooltip>
                    )}
                    {isLoading && (
                      <Fade in={true}>
                        <Box
                          sx={{
                            display: 'inline-flex',
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            p: 2,
                            boxShadow: 3,
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <CircularProgress size={24} />
                          <ShimmeringText>
                            {mode === 'replace' 
                              ? `Replacing "${inputText1}" with "${inputText2}"...`
                              : `Removing "${inputText1}"...`}
                          </ShimmeringText>
                        </Box>
                      </Fade>
                    )}
                  </Box>
                </Box>

                <Box sx={{ mt: 4, pt: 3, borderTop: 1, borderColor: 'grey.200' }}>
                  <form onSubmit={handleSubmit}>
                    <RadioGroup
                      row
                      value={mode}
                      onChange={(e) => setMode(e.target.value)}
                      sx={{ mb: 3 }}
                    >
                      <FormControlLabel 
                        value="replace" 
                        control={
                          <Radio 
                            sx={{
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        } 
                        label="Replace" 
                      />
                      <FormControlLabel 
                        value="remove" 
                        control={
                          <Radio 
                            sx={{
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        } 
                        label="Remove" 
                      />
                    </RadioGroup>

                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                      <TextField
                        fullWidth
                        value={inputText1}
                        onChange={(e) => setInputText1(e.target.value)}
                        label={mode === 'replace' ? "Text to replace" : "Text to remove"}
                        variant="outlined"
                        size="medium"
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                      />
                      
                      {mode === 'replace' && (
                        <TextField
                          fullWidth
                          value={inputText2}
                          onChange={(e) => setInputText2(e.target.value)}
                          label="Replace with"
                          variant="outlined"
                          size="medium"
                          sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                        />
                      )}
                      
                      <Button 
                        type="submit" 
                        variant="contained"
                        size="large"
                        sx={{ px: 4, minWidth: '120px' }}
                      >
                        Send
                      </Button>
                    </Box>
                  </form>
                </Box>
              </>
            )}
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App; 